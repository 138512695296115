export default defineNuxtPlugin(() => {
  //
  // Note: this is for Arkolia Samsung TV that does not have a builtin "structuredClone"
  //
  if (typeof structuredClone !== "function") {
    globalThis.structuredClone = function (obj) {
      return deepClone(obj)
    }

    function deepClone(obj: any): any {
      if (obj === null || typeof obj !== "object") return obj
      if (obj instanceof Date) return new Date(obj.getTime())
      if (obj instanceof RegExp) return new RegExp(obj)
      if (obj instanceof Map)
        return new Map([...obj.entries()].map(([k, v]) => [deepClone(k), deepClone(v)]))
      if (obj instanceof Set) return new Set([...obj].map(deepClone))
      if (Array.isArray(obj)) return obj.map(deepClone)
      if (typeof obj === "object")
        return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, deepClone(v)]))
      return obj
    }
  }
})
