import { defineStore } from "pinia"
import { z } from "zod"
import type { DropdownItem } from "#ui/types"
import type { DropdownItemScope, MeasureType, TSAcquisitionGroup } from "#imports"
import {
  BaseContractSchema,
  DocumentSchemaShort,
  EventSchema,
  InvoiceSchemaShort,
  NoteSchema,
  ReportTargetSchema,
  Scope,
  SiteSchemaOverview,
} from "#imports"

export const ActionKind = z.enum([
  "documents",
  "upload_documents",
  //
  "docgen",
  //
  "gs_refresh",
  //
  "dh_refresh",
  //
  "portfolio",
  "events",
  "excel_export",
  //
  "create_invoices",
  "invoice_actions", // compute / publish / submit
  "invoice_files", // invoice's PDF / Excel
  "invoice_set_submitted",
  "note_actions", // create / archive
])
export type ActionKind = z.infer<typeof ActionKind>

interface ActionStoreInterface {
  // Documents
  selectedDocuments: DocumentSchemaShort[]
  showDeleteDocumentModal: boolean

  showDocumentUploadModal: boolean

  // DocGen
  showDocGenModal: boolean
  selectedDocgenTargets: ReportTargetSchema[]

  // GoldenSource
  showGSRefreshModal: boolean
  selectedGSRefreshTargets: ReportTargetSchema[]

  // dataheath
  datahealthItems: DropdownItemScope[]

  // portfolio
  showSiteBackfillModal: boolean
  showSiteIntradayModal: boolean
  selectedTsAcquisitionGroupforBackfill: TSAcquisitionGroup | MeasureType | undefined
  selectedSProviderForBackfill: string | undefined
  showSiteEditTimeseriesModal: boolean
  siteHasEditableTimeseries: boolean
  siteHasEnedisTimeseries: boolean

  // events
  selectedEvents: EventSchema[]
  showDeleteEventModal: boolean

  // excel_export
  i18n: ReturnType<typeof useI18n> | undefined
  excelExportModel: "Contract" | "Invoice" | "Site" | "Event" | "Note"
  excelExportItems:
    | BaseContractSchema[]
    | InvoiceSchemaShort[]
    | SiteSchemaOverview[]
    | EventSchema[]
    | NoteSchema[]

  // create_invoices
  selectedInvoiceTargets: InvoiceComputeTarget[]
  showInvoiceCreateModal: boolean

  // invoice_actions
  invoiceActionsDropdownItems: DropdownItemScope[]

  // invoice_files
  invoiceFilesDropdownItems: DropdownItemScope[]

  // invoice_set_submitted
  invoiceSetSubmittedDropdownItems: DropdownItemScope[]

  noteActionsDropdownItems: DropdownItemScope[]
}

export const useActionStore = defineStore("action", {
  state: () =>
    ({
      // Documents
      selectedDocuments: [],
      showDeleteDocumentModal: false,
      showDocumentUploadModal: false,

      // DocGen
      showDocGenModal: false,
      selectedDocgenTargets: [],

      // GoldenSource
      showGSRefreshModal: false,
      selectedGSRefreshTargets: [],

      // Datahealth
      datahealthItems: [],

      // portfolio
      showSiteBackfillModal: false,
      showSiteIntradayModal: false,
      selectedSProviderForBackfill: undefined,
      selectedTsAcquisitionGroupforBackfill: undefined,
      showSiteEditTimeseriesModal: false,
      siteHasEditableTimeseries: false,
      siteHasEnedisTimeseries: false,

      // events
      selectedEvents: [],
      showDeleteEventModal: false,

      // excel_export
      i18n: undefined,
      excelExportModel: "Contract",
      excelExportItems: [],

      // create_invoices
      selectedInvoiceTargets: [],
      showInvoiceCreateModal: false,

      // invoice_actions
      invoiceActionsDropdownItems: [],

      // invoice_files
      invoiceFilesDropdownItems: [],

      // invoice_set_submitted
      invoiceSetSubmittedDropdownItems: [],

      // notes
      noteActionsDropdownItems: [],
    }) as ActionStoreInterface,
  getters: {
    actionDefs(state): Record<ActionKind, DropdownItem[]> {
      const docWithFile = state.selectedDocuments.filter((e) => e.file_exists)
      const mapItemsScopes: Record<ActionKind, DropdownItemScope[]> = {
        [ActionKind.enum.upload_documents]: [
          {
            label: "upload_contract",
            click: () => (state.showDocumentUploadModal = true),
            icon: "i-fa6-solid-file-pdf",
            scope: Scope.enum["upload:document"],
          },
        ],
        [ActionKind.enum.documents]: [
          {
            label: "download_files",
            click: () => downloadMultipleDocuments(docWithFile.map((e) => e.uuid)),
            disabled: docWithFile.length === 0,
            icon: "i-fa6-solid-download",
            scope: Scope.enum["read:document"],
          },
          {
            label: "delete_files",
            click: () => (state.showDeleteDocumentModal = true),
            disabled: state.selectedDocuments.length === 0,
            icon: "i-fa6-solid-trash",
            scope: Scope.enum["write:document"],
          },
        ],
        [ActionKind.enum.docgen]: [
          {
            label: "generate_report",
            icon: "i-fa6-solid-file-circle-plus",
            click: () => (state.showDocGenModal = true),
            disabled: state.selectedDocgenTargets.length === 0,
            scope: Scope.enum["write:document"],
          },
        ],
        [ActionKind.enum.gs_refresh]: [
          {
            label: "gs_refresh",
            icon: "i-material-symbols-sync-rounded",
            click: () => (state.showGSRefreshModal = true),
            disabled: state.selectedGSRefreshTargets.length === 0,
            scope: Scope.enum["write:goldensource"],
          },
        ],
        [ActionKind.enum.dh_refresh]: state.datahealthItems,
        [ActionKind.enum.portfolio]: [
          {
            label: "backfill",
            click: () => {
              state.showSiteBackfillModal = true
              state.selectedSProviderForBackfill = undefined
              state.selectedTsAcquisitionGroupforBackfill = undefined
            },
            icon: "i-material-symbols-data-table-outline",
            scope: Scope.enum["order:timeseries"],
          },
          {
            label: "intraday",
            click: () => {
              state.showSiteIntradayModal = true
            },
            icon: "i-fa6-regular-calendar",
            scope: Scope.enum["order:timeseries"],
            disabled: !state.siteHasEnedisTimeseries,
          },
          {
            label: "ts_input_data",
            click: () => {
              state.showSiteEditTimeseriesModal = true
            },
            icon: $icons.chart_line,
            scope: Scope.enum["write:timeseries"],
            disabled: !state.siteHasEditableTimeseries,
          },
        ],
        [ActionKind.enum.events]: [
          {
            label: "delete_events",
            click: () => (state.showDeleteEventModal = true),
            disabled: state.selectedEvents.length === 0,
            icon: "i-fa6-solid-trash",
            scope: Scope.enum["write:event"],
          },
        ],
        [ActionKind.enum.excel_export]: [
          {
            label: "export_data",
            click: () =>
              dataToExcel(
                state.excelExportModel,
                "xlsx",
                formatDataToExcel(state.excelExportModel, state.excelExportItems, state.i18n),
              ),
            disabled: state.excelExportItems.length === 0,
            icon: "i-fa6-solid-file-export",
            scope: undefined,
          },
        ],
        [ActionKind.enum.create_invoices]: [
          {
            label: "create_invoices",
            click: () => (state.showInvoiceCreateModal = true),
            disabled: state.selectedInvoiceTargets.length === 0,
            icon: "i-fa6-regular-file-lines",
            scope: Scope.enum["write:invoice"],
          },
        ],
        [ActionKind.enum.invoice_actions]: state.invoiceActionsDropdownItems,
        [ActionKind.enum.invoice_files]: state.invoiceFilesDropdownItems,
        [ActionKind.enum.invoice_set_submitted]: state.invoiceSetSubmittedDropdownItems,
        [ActionKind.enum.note_actions]: state.noteActionsDropdownItems,
      }

      const mapItems = R.mapValues(mapItemsScopes, (values) =>
        values
          .filter((item) => item.scope === undefined || hasScope(item.scope))
          .map((item) => R.pickBy(item, (_, key) => key !== "scope") as DropdownItem),
      )

      return mapItems
    },
  },
})
